import Configs from './../../../../config/default';
import { retrieve } from '../../../Utils/Retrieve';

export async function getSpecModel(props, locale, languages) {
  let fields = await retrieve(
    '',
    `${Configs.routes.gum.public}/spec/registration`
  );
  let countries = await retrieve(
    '',
    `${Configs.routes.gum.general}/userCountries`
  );
  const { statusText, data } = countries;
  countries = statusText && statusText !== 'OK' ? [] : data.ok;

  const { statusText: statusTextFields, data: dataFields } = fields;
  fields = statusTextFields && statusTextFields !== 'OK' ? {} : dataFields;

  return {
    fields: _getValidUserSpecModel(fields, countries, props, locale, languages),
  };
}

function _getValidUserSpecModel(fields, countries, props, locale, languages) {
  const { _t } = props;
  const langOptions = _getLanguageOptions(languages);
  const countryOptions = _getCountryOptions(countries);
  const selectedLanguage =
    langOptions.find((option) => option.value === locale) || null;
  const { spec } = fields;
  if (!spec) {
    return { errors: 'no_user_specs_found' };
  }
  let missingFields = [];
  const allowedFields = [
    {
      type: 'select',
      name: 'gender',
      label: 'gender',
      isSearchable: false,
      isClearable: false,
      selected: { value: 'n', label: '--' },
      options: [
        { value: 'n', label: '--' },
        { value: 'male', label: _t('male') },
        { value: 'female', label: _t('female') },
      ],
    },
    { type: 'text', name: 'givenName', label: 'firstName' },
    { type: 'text', name: 'sn', label: 'lastName' },
    {
      type: 'select',
      name: 'portalLanguage',
      label: 'communicationLanguage',
      defaultValue: null,
      isSearchable: false,
      isClearable: true,
      selected: selectedLanguage,
      options: langOptions,
    },
    { type: 'text', name: 'username', label: _t('userName') },
    { type: 'text', name: 'mail', label: _t('emailAddress') },
    { type: 'text', name: 'telephoneNumber', label: _t('telephoneNumber') },
    { type: 'text', name: 'mobile', label: _t('mobilePhoneNumber') },
    { type: 'text', name: 'company', label: _t('company') },
    { type: 'text', name: 'streetAddress', label: _t('postalAddress') },
    { type: 'text', name: 'postalcode', label: _t('postCode') },
    { type: 'text', name: 'localityName', label: _t('cityState') },
    {
      type: 'select',
      name: 'country',
      label: _t('country'),
      defaultValue: null,
      isSearchable: true,
      isClearable: true,
      options: countryOptions,
    },
    { type: 'hidden', name: 'send_token', label: '', value: true },
  ];

  allowedFields.forEach((field) => {
    if (spec.hasOwnProperty(field.name)) {
      field.rules = { ...spec[field.name] };
      field.required = !!field.rules.required;
      if (field.rules.not_empty) {
        field.required = !!field.rules.not_empty;
        delete field.rules.not_empty;
      }
    } else {
      missingFields.push(field.name);
    }
  });

  if (missingFields.length) {
    return { errors: `missing_fields ${missingFields.join()}` };
  }
  return allowedFields;
}

function _getLanguageOptions(availableLanguages) {
  const options = [];
  for (const [value, label] of Object.entries(availableLanguages)) {
    options.push({ value, label });
  }
  return options;
}

function _getCountryOptions(countries) {
  const options = [];
  countries &&
    countries.length &&
    countries.forEach((country) => {
      options.push({
        value: country.short_name,
        label: country.name,
      });
    });
  return options;
}

export async function saveUser(fields) {
  const model = {};
  fields.forEach((field) => {
    model[field.name] = field.value;
  });
  return await retrieve(
    '',
    `${Configs.routes.gum.public}/registration`,
    'POST',
    model
  );
}
